import React, { useState } from 'react';
import Barcode from 'react-barcode';

const App = () => {
  const labelStyles = {
    width: '2in',
    height: '4in',
    border: '1px solid black',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    pageBreakAfter: 'always',
  };

  const letterStyles = {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '5px',
    textTransform: 'uppercase',
  };

  const flightNumberStyles = {
    fontWeight: 'bold',
    fontSize: '16px',
  };

  const buttonStyles = {
    position: 'absolute',
    top: '5px',
    right: '5px',
    width: '20px',
    height: '20px',
    backgroundColor: 'red',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '12px',
  };

  const [flights, setFlights] = useState([
    {
      id: 1,
      flightNumber: '',
      barsetNumber: '',
      labelsSpare: [
        { letter: "Frozen Food" },
        { letter: "Ambient Box" },
        { letter: "A" },
        { letter: "B" },
        { letter: "C" },
        { letter: "D" },
        { letter: "E" },
        { letter: "Dry Store" },
      ],
      labelsRegular: [
        { letter: "Frozen Food" },
        { letter: "Ambient Box" },
        { letter: "A" },
        { letter: "B" },
        { letter: "C" },
        { letter: "D" },
        { letter: "E" },
        { letter: "Dry Store" },
      ],
    },
  ]);

  const [showSpare, setShowSpare] = useState(false);
  const [showRegular, setShowRegular] = useState(true);

  const handleAddFlight = () => {
    const newFlight = {
      id: flights.length + 1,
      flightNumber: '',
      barsetNumber: '',
      labelsSpare: [
        { letter: "Frozen Food" },
        { letter: "Ambient Box" },
        { letter: "A" },
        { letter: "B" },
        { letter: "C" },
        { letter: "D" },
        { letter: "E" },
        { letter: "Dry Store" },
      ],
      labelsRegular: [
        { letter: "Frozen Food" },
        { letter: "Ambient Box" },
        { letter: "A" },
        { letter: "B" },
        { letter: "C" },
        { letter: "D" },
        { letter: "E" },
        { letter: "Dry Store" },
      ],
    };
    setFlights([...flights, newFlight]);
  };

  const handleFlightInfoChange = (flightId, field, value) => {
    setFlights(
      flights.map(flight =>
        flight.id === flightId ? { ...flight, [field]: value } : flight
      )
    );
  };

  const handleDeleteLabel = (flightId, labelIndex, labelType) => {
    setFlights(
      flights.map(flight => {
        if (flight.id === flightId) {
          return {
            ...flight,
            [labelType]: flight[labelType].filter((_, index) => index !== labelIndex),
          };
        }
        return flight;
      })
    );
  };

  const handleDeleteFlight = (flightId) => {
    setFlights(flights.filter(flight => flight.id !== flightId));
  };

  const handleCheckboxChange = (type) => {
    if (type === 'spare') {
      setShowSpare(!showSpare);
    } else if (type === 'regular') {
      setShowRegular(!showRegular);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div>
      <div className="user-input-section">

        <button onClick={handleAddFlight} style={{ marginBottom: '20px' }}>Add Flight</button>
        <button onClick={handlePrint} style={{ marginBottom: '20px' }}>Print Labels</button>

        <div style={{ marginBottom: '20px' }}>
          <label>
            <input
              type="checkbox"
              checked={showSpare}
              onChange={() => handleCheckboxChange('spare')}
            />
            Show SPARE Labels
          </label>
          <label style={{ marginLeft: '10px' }}>
            <input
              type="checkbox"
              checked={showRegular}
              onChange={() => handleCheckboxChange('regular')}
            />
            Show REGULAR Labels
          </label>
        </div>

        {flights.map(flight => (
          <div key={flight.id} style={{ marginBottom: '40px' }}>
            <h2>Flight {flight.id}</h2>
            <button
              onClick={() => handleDeleteFlight(flight.id)}
              style={{
                marginBottom: '10px',
                color: 'white',
                backgroundColor: 'red',
                border: 'none',
                padding: '5px 10px',
                cursor: 'pointer',
              }}
            >
              Delete Flight
            </button>
            <div style={{ marginBottom: '20px' }}>
              <label>
                Flight Number:
                <input
                  type="text"
                  value={flight.flightNumber}
                  onChange={e =>
                    handleFlightInfoChange(flight.id, 'flightNumber', e.target.value)
                  }
                  style={{ marginRight: '10px', textTransform: 'uppercase' }}
                />
              </label>
              <label>
                Barset Number:
                <input
                  type="text"
                  value={flight.barsetNumber}
                  onChange={e =>
                    handleFlightInfoChange(flight.id, 'barsetNumber', e.target.value)
                  }
                  style={{ textTransform: 'uppercase' }}
                />
              </label>
            </div>
          </div>
        ))}
      </div>

      <div className="labels-section">
        {flights.map(flight => (
          <div key={flight.id} style={{ marginBottom: '40px' }}>
            {showSpare && (
              <>
                <h3>SPARE Labels for Flight {flight.id}</h3>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                  {flight.labelsSpare.map((label, index) => (
                    <div key={index} className="label" style={labelStyles}>
                      <div style={{ position: 'absolute', top: '5px', left: '5px', fontSize: '12px' }}>newrest</div>
                      <h3>Ryan Air</h3>
                      <p>Flight: <span style={flightNumberStyles}>{flight.flightNumber || "N/A"}</span></p>
                      <p>Barset: <span style={flightNumberStyles}>SPARE1</span></p>
                      <Barcode value="SPARE1" width={1} height={30} fontSize={10} />
                      <div style={letterStyles}>{label.letter}</div>
                      <button
                        style={buttonStyles}
                        onClick={() => handleDeleteLabel(flight.id, index, 'labelsSpare')}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              </>
            )}

            {showRegular && (
              <>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                  {flight.labelsRegular.map((label, index) => (
                    <div key={index} className="label" style={labelStyles}>
                      <div style={{ position: 'absolute', top: '5px', left: '5px', fontSize: '12px' }}>Newrest</div>
                      <h3>RYAN AIR</h3>
                      <p><span style={flightNumberStyles}>{flight.flightNumber || "N/A"}</span></p>
                      <p>Barset</p>
                      <p> <span style={flightNumberStyles}>{flight.barsetNumber || "N/A"}</span></p>
                      <Barcode value={flight.barsetNumber || "N/A"} width={1} height={30} fontSize={10} />
                      <div style={letterStyles}>{label.letter}</div>
                      <button
                        style={buttonStyles}
                        onClick={() => handleDeleteLabel(flight.id, index, 'labelsRegular')}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        ))}
      </div>

      <style>
        {`
          @media print {
            body * {
              visibility: hidden;
            }

            .labels-section, .labels-section * {
              visibility: visible;
            }

            .label {
              page-break-after: always;
              width: 2in;
              height: 4in;
              margin: 0;
            }

            @page {
              margin: 0;
            }
          }
        `}
      </style>
    </div>
  );
};

export default App;
